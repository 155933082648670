import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import buttonRowPreviewData from '../../../../data/previews/button-row.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const ButtonRow = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ButtonRow;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Button Row"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Buttons"
        tierThree="Button Row"
      />
      <V5Notice
        componentName="Button Row"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-buttons--docs#button-row"
      />

      <PageNavigation links={['Space', 'Usage', 'Accessibility', 'Platform']} />

      <Section title="Space">
        <Paragraph>
          Our space scale is based on a 16px unit. When creating a button row,
          margin sizes can range from one-eighth (2px) to four full spaces.
        </Paragraph>
        <ComponentPreview
          name="ButtonType"
          layout="split"
          previewData={buttonRowPreviewData.spaces}>
          <ButtonRow>
            <Button type="subtle">Edit</Button>
            <Button type="primary">Save</Button>
          </ButtonRow>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Button Sizes</SectionSubhead>
        <DontDo
          dontText="mix and match button sizes in a single row."
          doText="make the buttons as similar as possible—this includes text length."
          imgFilename="buttonrow-buttontypes"
        />

        <SectionSubhead>Many Buttons</SectionSubhead>
        <Paragraph>
          Some interfaces require multiple buttons to perform actions or
          specific functions. In these cases, use one style of button to keep
          the interface from becoming overwhelming.
        </Paragraph>

        <DontDo
          dontText="mix button type and style to emphasize one button among many."
          doText="keep buttons consistent so as not to overload the interface."
          imgFilename="buttonrow-minimal"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Button Row" />

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
